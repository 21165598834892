import Login, { Logout } from './Login'
// import { authRoles } from 'modules/auth'

export const LoginConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false
        },
        toolbar: {
          display: false
        },
        footer: {
          display: false
        },
        leftSidePanel: {
          display: false
        },
        rightSidePanel: {
          display: false
        }
      }
    }
  },
  // auth: authRoles.onlyGuest, // Enable to redirect back to /projects
  routes: [{
    path: '/login',
    component: Login
  }, {
    path: '/logout',
    component: Logout
  }]
}
