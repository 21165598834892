import io from 'socket.io-client'
// import Logger from 'modules/logger/log.module'

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || 'https://redwood-api.anexsys.net'
const REQUEST_TIMEOUT_MILLIS = 15000
const socket = io(SOCKET_URL)

let corrId = 0

const log = console && console.info ? console.info : console.log
const logError = console && console.error ? console.error : console.log
const successStyle = 'background: #222; color: #bada55'
const failStyle = 'background: #222; color: #DEADa5'

export const corrIdGen = () => `${socket.id}_${(corrId = corrId + 1)}`

socket.on('connect', () => {
  log('Socket Connected')
})

socket.on('connect_error', (error) => {
  logError(error)
})

socket.on('reconnect_attempt', (attempt) => {
  log(`Attempting to reconnect socket: ${attempt}`)
})

const currentStreams = new Map()
const clearUp = corrId => {
  const ongoing = currentStreams.get(corrId)
  if (ongoing) {
    // not been cleared up by timeout
    clearTimeout(ongoing.timeout)
    currentStreams.delete(corrId)
    return ongoing
  }
  return null
}

socket.on('__HANDSHAKE__', (corrId, request) => {
  // only fired if no correlation ID passed, so the server-generated one is associated with the request for the client
  log(`CorrID: ${corrId}`)
  // operation started - expect data
})

socket.on('__DATA__', (corrId, type, chunk, encoding) => {
  const ongoing = currentStreams.get(corrId)
  if (ongoing) {
    // not been cleared up by timeout or error
    ongoing.dataHandler(corrId, type, chunk, encoding)
  }
})

export function stream (op, request, dataHandler) {
  console.group('Websocket Stream:')
  return new Promise((resolve, reject) => {
    // record request against correlation ID and set it to clear up after given timeout (default 30 secs)
    currentStreams.set(request.corrId, {
      request,
      dataHandler,
      timeout: setTimeout(() => clearUp(request.corrId), REQUEST_TIMEOUT_MILLIS)
    })

    socket.emit(op, request, (error, [endSignal, corrId]) => {
      clearUp(corrId)

      // handle err and perform any stream completion routines
      if (error) {
        if (typeof error === 'object' && error.hasOwnProperty('code') && error.hasOwnProperty('details')) {
          const message = `Error: ${op} :: Code: ${error.code} - ${error.details}`
          logError(`API Error Stream Response: %c${message}\n`, failStyle, error)
          console.groupEnd()
          return reject(new Error(message))
        }
        // Fallback - try to discern error type
        const message = `Error: ${op} :: ${(typeof error === 'string') ? error : (Error.isError(error)) ? error && error.message : error}`
        logError(`API Error Stream Response: %c${message}\n`, failStyle, error)
        console.groupEnd()
        return reject(new Error(message))
      }

      log(`API Stream Response: %c ${op}\n`, successStyle, corrId)
      if (error) {
        return reject(error)
      }
      resolve(request)
      console.groupEnd()
    })
  })
}

export function rpc (op, data, resultMap) {
  console.group('Websocket Request:')
  return new Promise((resolve, reject) => {
    socket.emit(op, data, (error, response) => {
      if (error) {
        if (typeof error === 'object' && error.hasOwnProperty('code') && error.hasOwnProperty('details')) {
          const message = `Error: ${op} :: Code: ${error.code} - ${error.details}`
          logError(`API Error Stream Response: %c${message}\n`, failStyle, error)
          console.groupEnd()
          return reject(new Error(message))
        } else if (error && typeof error === 'string' && error === '2 UNKNOWN: Stream removed') {
          return rpc(op, data, resultMap) // retry
        }
        const message = `Error: ${op} :: ${(typeof error === 'string') ? error : (Error.isError(error)) ? error && error.message : error}`
        logError(`API Error Response: %c${message}\n`, failStyle, error)
        console.groupEnd()
        return reject(new Error(message))
      }
      log(`API Response: %c ${op}\n`, successStyle, response)
      // Logger.log(`API Response: ${op}`)
      console.groupEnd()
      if (resultMap) {
        resolve(resultMap(response))
      } else {
        resolve(response)
      }
    })
  })
}

export default {
  corrIdGen,
  rpc,
  stream
}
