import axios from 'axios'
import jwtDecode from 'jwt-decode'
import FuseUtils from 'modules/@fuse/FuseUtils'
import queryString from 'query-string'

const log = console && console.info ? console.info : console.log
const logError = console && console.error ? console.error : console.log
const successStyle = 'background: #222; color: #bada55'
const failStyle = 'background: #222; color: #DEADa5'

class JWTService extends FuseUtils.EventEmitter {
  init () {
    console.group('JWT Authentication:')
    this.setInterceptors()
    this.handleAuthentication()
    console.groupEnd()
  }

  setInterceptors = () => {
    axios.interceptors.response.use(response => {
      return response
    }, err => {
      return new Promise((resolve, reject) => {
        if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
          // if you ever get an unauthorized response, logout the user
          this.emit('onAutoLogout', 'Invalid accessToken')
          this.setSession(null)
        }
        throw err
      })
    })
  }

  isAuthenticated = () => {
    return this.authenticated
  }

  handleAuthentication = () => {
    let accessToken = this.getAccessToken()

    if (!accessToken) {
      // Check if we have been given a token via url
      const urlToken = this.getUrlAccessToken()
      if (!urlToken) {
        logError('No Token found in localStorage or location')
        this.authenticated = false // Essentially we assume all is bad here
        this.setSession(null)
        this.emit('onAutoLogout', 'accessToken expired')
        return
      }
      log('Auth token taken from URL')
      accessToken = urlToken
    }

    if (this.isAuthTokenValid(accessToken)) {
      log('AccessToken is: %c Valid', successStyle)
      this.authenticated = true // Essentially we assume all is good here
      this.setSession(accessToken)
      this.emit('onAutoLogin', true)
    } else {
      log('AccessToken is: %c inValid', failStyle)
      this.authenticated = false // Essentially we assume all is bad here
      this.setSession(null)
      this.emit('onAutoLogout', 'accessToken expired')
    }
  }

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post('/api/auth/register', data)
        .then(response => {
          if (response.data.user) {
            this.setSession(response.data.accessToken)
            resolve(response.data.user)
          } else {
            reject(response.data.error)
          }
        })
    })
  }

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios.get('/api/auth', {
        data: {
          email,
          password
        }
      }).then(response => {
        if (response.data.user) {
          this.setSession(response.data.accessToken)
          resolve(response.data.user)
        } else {
          reject(response.data.error)
        }
      })
    })
  }

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      const jwt = this.getAccessToken()
      if (!jwt) {
        reject(new Error('No local token found'))
      }

      const decoded = jwtDecode(jwt)
      const validJWT = decoded.isAnexsysUser && typeof decoded.anexsysId === 'string'
      if (validJWT) {
        const user = {
          role: 'staff',
          data: {
            settings: {}
          }
        }

        Object.assign(user.data, decoded)
        this.setSession(jwt)
        resolve(user)
      } else {
        reject(new Error('Invalid JWT supplied'))
      }

      // axios.get('/api/auth/access-token', {
      //   data: {
      //     accessToken: this.getAccessToken()
      //   }
      // }).then(response => {
      //   if ( response.data.user ) {
      //     this.setSession(response.data.accessToken)
      //     resolve(response.data.user)
      //   } else {
      //     reject(response.data.error)
      //   }
      // })
    })
  }

  updateUserData = (user) => {
    return axios.post('/api/auth/user/update', {
      user: user
    })
  }

  setSession = accessToken => {
    if (accessToken) {
      window.localStorage.setItem('jwt_accessToken', accessToken)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
    } else {
      window.localStorage.removeItem('jwt_accessToken')
      delete axios.defaults.headers.common['Authorization']
    }
  }

  logout = () => {
    this.authenticated = false
    this.setSession(null)

    // window.location.pathname = '/login' // redirect page to re-run Auth component
    // window.location.reload() // refresh page to re-run Auth component
  }

  isAuthTokenValid = accessToken => {
    if (!accessToken) {
      return false
    }
    try {
      const decoded = jwtDecode(accessToken)
      const currentTime = Date.now() / 1000
      if (decoded.exp < currentTime) {
        console.warn('access token expired')
        return false
      } else {
        return true
      }
    } catch (err) {
      const msg = `Invalid AccessToken supplied: ${err.message}`
      console.error(msg)
      return false
    }
  }

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_accessToken')
  }

  getUrlAccessToken = () => {
    const url = window.location
    const query = queryString.parse(url.search)
    if (query && query.token) {
      return query.token
    }
  }
}

const instance = new JWTService()

export default instance
