import { authRoles } from 'modules/auth'

export const fuseNavigationConfig = [
  {
    'id': 'applications',
    'title': 'Menu',
    'type': 'group',
    'icon': 'apps',
    'children': [
      // {
      //   'id': 'landing-component',
      //   'title': 'Home',
      //   'type': 'item',
      //   'icon': 'home',
      //   'url': '/home'
      // },
      {
        'id': 'project-list-component',
        'title': 'Projects',
        'type': 'item',
        'icon': 'work',
        'url': '/projects',
        'auth': authRoles.staff
      }
    ]
  }
]
