import { fuseDark } from 'modules/@fuse/fuse-colors'
import { lightBlue, red, orange } from '@material-ui/core/colors'

const anexsysDefault = require('./brandColours/anexsys.colours.json')
const hobsDefault = require('./brandColours/hobs.colours.json')

export const fuseThemesConfig = {
  default: {
    palette: {
      type: 'light',
      primary: fuseDark,
      secondary: {
        light: lightBlue[400],
        main: lightBlue[600],
        dark: lightBlue[700]
      },
      error: red
    },
    status: {
      danger: 'orange'
    }
  },
  anexsys: {
    palette: {
      type: 'light',
      primary: anexsysDefault,
      secondary: {
        light: anexsysDefault['A400'], // '#CEDE81',
        main: anexsysDefault['A600'], // '#BCD253',
        dark: anexsysDefault['A700'], // '#89993D'
        textOnMain: 'rgba(0, 0, 0, 0.87)'
      },
      error: orange
    },
    status: {
      danger: 'orange'
    }
  },
  hobshare: {
    palette: {
      type: 'light',
      primary: hobsDefault,
      secondary: {
        light: hobsDefault['A400'], // '#CEDE81',
        main: hobsDefault['A600'], // '#BCD253',
        dark: hobsDefault['A700'], // '#89993D'
        textOnMain: '#FAFAFA'
      },
      error: red
    },
    status: {
      danger: 'red'
    }
  }
}
