import React from 'react'
import { injectReducer } from 'client/store'

const withReducer = (key, reducer) => WrappedComponent =>
  class extends React.PureComponent {
    constructor (props) {
      super(props)
      injectReducer(key, reducer)
    }

    render () {
      return <WrappedComponent {...this.props} />
    }
  }

export const withMultipleReducers = (array) => WrappedComponent =>
  class extends React.PureComponent {
    constructor (props) {
      super(props)
      array.forEach(reducerObj => {
        if (!reducerObj.hasOwnProperty('key') || !reducerObj.hasOwnProperty('reducer')) {
          throw new Error(`Something wrong passing in multiple reducers:\n${JSON.stringify(array, '', 2)}`)
        }
        injectReducer(reducerObj.key, reducerObj.reducer)
      })
    }

    render () {
      return <WrappedComponent {...this.props} />
    }
  }

export default withReducer
