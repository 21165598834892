import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as userActions from 'modules/auth/store/actions'
import * as Actions from 'modules/@fuse/store/actions'

import jwtService from 'modules/auth/jwtService'

export const USE_ANEXSYS_AUTH = process.env.REACT_APP_ANEXSYS_AUTH_REDIRECTION
  ? process.env.REACT_APP_ANEXSYS_AUTH_REDIRECTION === 'true'
  : true

class Auth extends Component {
  constructor (props) {
    super(props)
    this.jwtCheck()
  }

  static isAuthenticated = () => {
    return jwtService.isAuthenticated()
  }

  jwtCheck = () => {
    jwtService.on('onAutoLogin', () => {
      this.props.showMessage({
        message: 'Logging in with JWT',
        autoHideDuration: 1000,
        variant: 'default'
      })
      /**
         * Sign in and retrieve user data from Api
         */
      jwtService.signInWithToken()
        .then(user => {
          this.props.setUserData(user)
          this.props.showMessage({
            message: 'Logged in with JWT',
            autoHideDuration: 1500,
            variant: 'success'
          })
        })
        .catch(error => {
          this.props.showMessage({
            message: error.message || error,
            autoHideDuration: 6000,
            variant: 'error'
          })
        })
    })

    jwtService.on('onAutoLogout', (message) => {
      if (message) {
        this.props.showMessage({
          message,
          autoHideDuration: 2000,
          variant: 'info'
        })
      }
      this.props.logout()

      // if (USE_ANEXSYS_AUTH) {
      //   setTimeout(() => {
      //     window.location = 'https://auth.anexsys.net'
      //   }, 500)
      // }
    })

    jwtService.init()
  }

  render () {
    const { children } = this.props

    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    )
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    logout: userActions.logoutUser,
    setUserData: userActions.setUserData,
    showMessage: Actions.showMessage,
    hideMessage: Actions.hideMessage
  },
  dispatch)
}

export default connect(null, mapDispatchToProps)(Auth)
