import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import jspath from 'jspath'

import classNames from 'classnames'
import { Card, CardContent, Typography, LinearProgress, Icon, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'

import { FuseAnimate } from 'modules/@fuse'

import { USE_ANEXSYS_AUTH } from 'modules/auth/Auth'
import jwtService from 'modules/auth/jwtService'

const styles = theme => ({
  root: {
    background: "url('/assets/images/backgrounds/dark-material-bg.jpg') no-repeat",
    backgroundSize: 'cover'
  },
  intro: {
    color: '#ffffff'
  },
  card: {
    width: '100%',
    maxWidth: 400
  }
})

class Login extends Component {
  state = {
    tabValue: 0
  }

  componentDidMount () {
    if (USE_ANEXSYS_AUTH && !jwtService.isAuthenticated()) {
      this.redirectToAuthSite()
    } else if (jwtService.isAuthenticated()) {
      console.log('AUTHENTICATED IN LOGIN PAGE')
      const redirectBecauseAuthenticated = jspath('.history.location.state.redirectUrl[0]', this.props) || '/'
      this.props.history.push({
        pathname: redirectBecauseAuthenticated
      })
    }
  }

  redirectToAuthSite = () => {
    this.setState({
      ...this.state,
      redirecting: true
    })
    setTimeout(() => {
      window.location = 'https://auth.anexsys.net'
    }, 200)
  }

  handleTabChange = (event, value) => {
    this.setState({ tabValue: value })
  }

  render () {
    const { classes } = this.props

    if (this.state.redirecting) {
      return (
        <div className='flex flex-1 flex-col items-center justify-center' style={{ background: '#FAFAFA' }}>
          <Typography className='text-20 mb-16' color='textSecondary'>Redirecting...</Typography>
          <LinearProgress className='w-xs' color='secondary' />
        </div>
      )
    }

    return (
      <div className={classNames(classes.root, 'flex flex-col flex-1 flex-no-shrink p-24 md:flex-row md:p-0')}>

        <div className={classNames(classes.intro, 'flex flex-col flex-no-grow items-center p-16 text-center md:p-128 md:items-start md:flex-no-shrink md:flex-1 md:text-left')}>
          <FuseAnimate animation='transition.expandIn'>
            <img className='w-128 mb-32' src='assets/images/logos/fuse.svg' alt='logo' />
          </FuseAnimate>

          <FuseAnimate animation='transition.slideUpIn' delay={300}>
            <Typography variant='h3' color='inherit' className='font-light'>
              Welcome to Papyrus!
            </Typography>
          </FuseAnimate>
        </div>

        <FuseAnimate animation={{ translateX: [0, '100%'] }}>
          <Card className={classNames(classes.card, 'mx-auto m-16 md:m-0')}>
            <CardContent className='flex flex-col items-center justify-center p-32 md:p-48 md:pt-128 '>
              <div className='p-24'>
                <div className='flex flex-1 flex-col items-center justify-center'>
                  <Icon className='text-40 mb-16' color='action'>person_outline</Icon>
                  <Typography className='text-20 mb-16' color='textSecondary'>
                    Login to your account:
                  </Typography>
                  <Button onClick={this.redirectToAuthSite} variant='contained' color='secondary'>Login</Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </FuseAnimate>
      </div>
    )
  }
}

export class Logout extends Component {
  componentDidMount () {
    jwtService.logout()
    setTimeout(() => {
      if (!USE_ANEXSYS_AUTH) {
        this.props.history.push({
          pathname: '/login'
        })
      } else {
        window.location = 'https://auth.anexsys.net/auth/signout'
      }
    }, 500)
  }
  render () {
    return (
      <div className='flex flex-1 flex-col items-center justify-center' style={{ background: '#FAFAFA' }}>
        <Typography className='text-20 mb-16' color='textSecondary'>Logging out...</Typography>
        <LinearProgress className='w-xs' color='secondary' />
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(Login))
