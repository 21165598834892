import React from 'react'
import { Redirect } from 'react-router-dom'

import { FuseUtils } from 'modules/@fuse/index'
import { LoginConfig } from 'client/app/sectors/login/LoginConfig'

import { LandingConfig } from 'client/app/sectors/LandingPage/Landing.config'
import { ProjectListConfig } from 'client/app/sectors/ProjectList/ProjectList.config'
import { DocumentListConfig } from 'client/app/sectors/DocumentList/DocumentList.config'
import { DocumentViewerConfig } from 'client/app/sectors/DocumentViewer/DocumentViewer.config'

const routeConfigs = [
  LoginConfig,
  LandingConfig,
  ProjectListConfig,
  DocumentViewerConfig, // Must be above list - to priority match first
  DocumentListConfig
]

export const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: '/',
    component: () => <Redirect to='/projects' />
  }
]
