import FuseLoadable from 'modules/@fuse/components/FuseLoadable/FuseLoadable'

export const LandingConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: '/home',
      component: FuseLoadable({
        loader: () => import('./Landing')
      })
    }
  ]
}
